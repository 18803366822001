/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// properly place the TU Dortmund logo in the navigation bar
.home-navbar-logo {
  width: 208px;
  height: 34px;
  background: url('./assets/navbar-logo.svg');
  background-repeat: no-repeat;
  background-size: auto 34px;
  background-position: center center;
  margin-left: 5px;
}

ion-content .table {
  margin-top: 1em;
  margin-bottom: 1em;

  tr:nth-child(even) {
    background-color: var(--ion-color-light);
  }

  td,
  th {
    padding: 0.5em;
    border: 1px solid black;
    border-collapse: separate;
  }
}

// Workaround for this https://github.com/ionic-team/ionic-framework/issues/23959 / https://github.com/ionic-team/ionic-framework/issues/20087
ion-tabs,
.tabs-inner,
ion-router-outlet,
.ion-page {
  contain: size style !important;
}

// Campusnavi Popovers
.building-poi {
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;

  .room {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: .25em;
  }

  .description {
    display: flex;
    flex-direction: column;
  }
}
